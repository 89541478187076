import ladeTannhelsesenter from "../assets/images/lade_tannhelsesenter.webp"
import invisalign from "../assets/images/invisalign.webp"
import teeth from "../assets/images/teeth.webp"
import toothImplant from "../assets/images/tannimplantat.webp"
import tannlegevakt from "../assets/images/tannlegevakt.webp"
import syreskader from "../assets/images/syreskader.webp"

export type Article = {
  id: string
  title: string
  description: string
  short: string
  image: string
}

const articles: Article[] = [
  {
    id: "usynlig-regulering",
    title: "Usynlig regulering",
    description: "Invisalign og andre tannreguleringstyper",
    short:
      "Usynlig regulering, også kjent som Invisalign eller skjult regulering er en tilnærming til tannregulering uten synlige metallkomponenter.",
    image: invisalign,
  },
  {
    id: "tannlegeskrekk",
    title: "Tannlegeskrekk",
    description: "Årsaker og behandling",
    short:
      "Tannlegeskrekk, eller odontofobi, er en utbredt angst for tannbehandling som berører mange mennesker.",
    image: ladeTannhelsesenter,
  },
  {
    id: "tanntrekking",
    title: "Tanntrekking",
    description: "Fjerning av tenner",
    short:
      "Fjerning av tenner er en vanlig behandling som forhindrer skader og smerter ved uønsket tanntilstand.",
    image: teeth,
  },
  {
    id: "tannimplantat",
    title: "Tannimplantat",
    description: "Erstatning av tenner",
    short:
      "Tannimplantater representerer den mest avanserte teknologien for å erstatte tapte tenner og gjenopprette både funksjon og estetikk i munnen.",
    image: toothImplant,
  },
  {
    id: "tannlegevakt",
    title: "Tannlegevakt",
    description: "Akutt behov",
    short: "Tannlegevakt behandler akutt behov utenfor ordinær åpningstid.",
    image: tannlegevakt,
  },
  {
    id: "syreskader",
    title: "Syreskader",
    description: "Forebygging og behandling av syreskader på tennene",
    short:
      "Syreskader på tennene, også kjent som dentale erosjoner, kan føre til betydelige tannhelseproblemer.",
    image: syreskader,
  },
]

export default articles
