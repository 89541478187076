type MenuProps = {
  onSetShowMenu: (showMenu: boolean) => void
  onNavigateWrapper: (path: string, options?: { refresh?: boolean }) => void
}

const Menu = ({ onSetShowMenu, onNavigateWrapper }: MenuProps) => {
  const MenuItem = ({
    path,
    onClick,
    refresh = false,
    children,
    className = "",
  }: {
    path?: string
    onClick?: () => void
    refresh?: boolean
    children: React.ReactNode
    className?: string
  }) => (
    <button
      className={`border-b border-primary mb-1 text-2xl font-bold hover:text-focus hover:border-focus ${className}`}
      onClick={() => {
        if (path) {
          onNavigateWrapper(path, { refresh })
        } else if (onClick) {
          onClick()
        }
      }}
    >
      {children}
    </button>
  )

  return (
    <div className="shadow-lg w-screen -mt-4 md:mt-0">
      <div className="h-20" onClick={() => onSetShowMenu(false)} />
      <div className="bg-background-light text-primary-accent p-8 flex flex-col whitespace-nowrap items-end md:pr-32">
        <MenuItem
          className="block md:hidden"
          onClick={() =>
            window.open("https://myanita.no/login?clinic_source=41", "_blank")
          }
        >
          Min side
        </MenuItem>
        <MenuItem path="/aktuelt">Aktuelt</MenuItem>
        <MenuItem path="/info/om-oss">Om oss</MenuItem>
        <MenuItem path="/info/behandlinger">Behandlinger</MenuItem>
        <MenuItem path="/priser">Priser</MenuItem>
        <MenuItem path="/info/stillinger">Jobbe hos oss</MenuItem>
        <MenuItem path="/info/finansiering">Finansiering</MenuItem>
      </div>
    </div>
  )
}

export default Menu
